import React from "react";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from "react-router-dom"

export default function UpBar(props){
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false)
    };

    return(
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Box sx={{width: anchoSegunBreakpoint(props.size), height: 1}} />
                <Typography variant="h6" style={{cursor: 'pointer', color: 'inherit', textDecoration: 'none', marginTop: 10, marginBottom: 10, marginRight: 20}} component={Link} to={'/'} sx={{ flexGrow: 1, fontWeight: 700 }}>Public health in mega-cities and rapidly growing cites; the environmental dimension.</Typography>
                
                <MenuBreakpoints size={props.size} navigate={navigate} handleClick={handleClick} menuOpen={menuOpen} anchorEl={anchorEl} handleClose={handleClose} />

                <Box sx={{width: anchoSegunBreakpoint(props.size), height: 1}} />
            </Toolbar>
        </AppBar>
    )
}

function MenuBreakpoints(props){
    if (props.size["bpoint"] === "xl" || props.size["bpoint"] === "lg" /* || props.size["bpoint"] === "md" */){
        return (
            <React.Fragment>
                <Button component={Link} to={'/'} variant="contained" disableElevation color="secondary" style={{marginRight: "2vh"}}>Home</Button>
                <Button component={Link} to={'/index'} variant="contained" disableElevation color="secondary" style={{marginRight: "2vh"}}>Index</Button>
                <Button component={Link} to={'/members'} variant="contained" disableElevation color="secondary" >Members</Button>
            </React.Fragment>
        )
    }
    return(
        <React.Fragment>
            {/* <IconButton variant="contained" onClick={props.handleClick}> <MenuIcon /> </IconButton> */}
            <Button variant="contained" color="secondary" onClick={props.handleClick} disableElevation alt="Boton for pages menu (alt)" aria-label="Boton for pages menu (aria)" > <MenuIcon /> </Button>
            <Menu 
                open={props.menuOpen} 
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem> <Button variant="contained" component={Link} to={'/'} fullWidth disableElevation color="secondary" onClick={() => { props.handleClose() }} style={{marginRight: "0vh",}}>Home</Button> </MenuItem>
                <MenuItem> <Button variant="contained" component={Link} to={'/index'} fullWidth disableElevation color="secondary" onClick={() => { props.handleClose() }} style={{marginRight: "0vh",}}>Index</Button> </MenuItem>
                <MenuItem> <Button variant="contained" component={Link} to={'/members'} fullWidth disableElevation color="secondary" onClick={() => { props.handleClose() }} style={{marginRight: "0vh",}}>Members</Button> </MenuItem>
            </Menu>
        
        </React.Fragment>
    )
}

function anchoSegunBreakpoint(size){
    if (size["bpoint"] === "xl" || size["bpoint"] === "lg") {
        return (size.width - 1210) / 2 
    }
    return 0
}
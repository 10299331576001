import React from "react";
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import LinkMUI from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

import TipsAndUpdates from "@mui/icons-material/TipsAndUpdates";

import IntroBox1 from "../components/IntroBox1";
import IntroBox2 from "../components/IntroBox2";
import TheBox from "../components/TheBox";
import mapaUN2018 from "../resources/mapaUN2018-2018.png"
import figura2014 from "../resources/2014.png"
import IntroActivity1 from "./IntroActivity1";
import IntroActivity2 from "./IntroActivity2";
import ImagenCentrada from "./ImagenCentrada";

export default function Introduccion(props){
    const [dialogoAbierto, setDialogoAbierto] = React.useState(0)

    const handleDialogClose = () => {
        setDialogoAbierto(0)
    }

    return(
        <React.Fragment>
            <Container maxWidth="lg">
                <Typography variant="h5" style={{marginTop: 10, marginBottom: 10}}>Introduction </Typography>
                <LinkMUI href=" https://razonyrevolucion.org/negocios-villeros-la-lucha-de-la-villa-31-y-la-disputa-por-la-renta-urbana/" target="_blank" color="error">Index image source</LinkMUI>
                <Typography align="justify">
                    This section provides an overview of the links between the rapid growth of cities and public health challenges, particularly throughout the so-called “Global South.” 
                </Typography>
                <Typography align="justify">
                    Students are introduced to the social determinants of health in increasingly complex urban environments. 
                    After reading this section, students write a short essay (150 words or less) describing the links between the built environment and health policy concerning their own cities. 
                    Then they will engage in step-wise case-based discussions, while showing how data illuminate ethical, economic, social, environmental and public health issues in rapidly growing cities.
                </Typography>
                <Typography align="justify"> {/* Pasar a link sin mencion explicita */}
                    The following sections build upon <LinkMUI href="https://publichealthinmegacities.wordpress.com/course-overview" target="_blank" color="error">recently developed teaching resources</LinkMUI>.   
                    Curious readers will observe work in progress; future editions will share novel topics of public health growing concern. 
                </Typography>

                <TheBox />
                <TheBox/>
                <Divider  style={{marginLeft:100, marginRight:100}}/>
                <TheBox/>

                <Typography variant="h6" style={{marginTop: 10, marginBottom: 10}}>
                    Overall learning goals: 
                </Typography>
                <Typography>
                    Box 1. Students will learn historical lessons from ancient megalopolis and public health.
                </Typography>

                <TheBox />
                <IntroBox1 />
                <TheBox />

                <Tooltip title="Suggested reading: Frederick Engels, 'The Condition of the Working Class in England', contained in the Collected Works of Karl Marx and Frederick Engels: Volume 4 (International Publishers: New York, 1975) pp. 295–596."> 
                <Typography align="justify"> 
                    Shortly after the Industrial Revolution,   only 3% of the world population lived in cities. 
                    F. Engels described the conditions of the working class population in industrial cities (e.g., Manchester and Liverpool), living in overcrowded slums, with no basic sanitation. 
                    Sewage was discharged into nearby rivers, resulting in frequent outbreaks of cholera. 
                    By 1900, London was the largest post-industrial city (over 5 million inhabitants).    
                    <TipsAndUpdates sx={{ fontSize: 12 }} color="error" style={{ marginTop: 0, marginLeft: 10}} />
                </Typography>
                </Tooltip>

                <TheBox />     
                <TheBox/>
                <Divider style={{marginLeft:100, marginRight:100}}/>
                <TheBox />
                <TheBox/>       

                <Typography align="justify">
                    Box 2. Students begin discussions regarding the built environment and public health principles,  both of which will be framed into social sciences’ 
                    theory and concepts (see Conceptual Framework 1). 
                    Examples may initially target both the history and political determinants of health in cities from Latin America and the Caribbean.
                </Typography>

                <TheBox />
                <IntroBox2 />
                <TheBox />

                <Typography align="justify">
                    By 1950, New York city was the only urban area with a population of 10 million. 
                    Since then, cities of less than ten million inhabitants have created the second wave of urbanization, mostly throughout developing countries from the “South”. 
                    The United Nations  estimated that the number of megacities with a population of more than 10 million will triple from 20 in 2003 to 85 in 2025.   
                    Approximately 280 million people currently reside in megacities, and this number is likely to rise to 350 million in the coming years. 
                    This rapidly growing cities have much poorer infrastructure and will be increasingly vulnerable to health risk.   
                </Typography>

                <TheBox />
                <TheBox />

                <ImagenCentrada image={mapaUN2018} text="Map 1. All cities around the world, according to size | Source: World Urbanization Prospects. The 2018 Revision (United Nations)" width="md" />

                <Divider style={{marginTop:20, marginBottom:20 ,marginLeft:100, marginRight:100}}/>

                <ImagenCentrada image={figura2014} text="Figure 1. City population evolution | Source: World Urbanization Prospects. The 2014 Revision (United Nations)" width="md" />

                <TheBox/>

                <Typography align="justify">
                    As the world population reached 8 billion and keeps growing, most of us will be urban dwellers.   
                    By 2030,  over 2 billion people will be living in slums or ‘villas miseria’ showing high rates of infectious diseases, 
                    environmental hazards, weak health services and poor infrastructure.  (Chapter 2). 
                </Typography>

                <TheBox />

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={6} style={{}}>
                        <Button variant="contained" disableElevation fullWidth sx={{minHeight: "20vw"}} onClick={() => {setDialogoAbierto(1)}} > <Typography variant="h5" sx={{ fontWeight: 600 }}>Activity 1</Typography> </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{display: 'flex', }}>
                        <Button variant="contained" disableElevation fullWidth sx={{minHeight: "20vw"}} onClick={() => {setDialogoAbierto(2)}}> <Typography variant="h5" sx={{ fontWeight: 600 }}>Activity 2</Typography> </Button>
                    </Grid>
                </Grid>

                <TheBox />
                <TheBox />

                <Typography variant="h4">Chapter 1 annex:</Typography>
                <TheBox />

                <Stack direction="row" justifyContent="center">
                    <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRZnddkvpCe8tudPZe0IYPLD-VkY_kueWN834lZslCj_qGx2WwXqGGxxTXxRx1cug/embed?start=true&loop=false&delayms=10000" 
                    frameBorder="0" width={anchoSegunBreakpoint(props.size)} height={ anchoSegunBreakpoint(props.size) * 0.595} allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" title="Powerpoint presentation for Chapter 2"></iframe>
                </Stack>

                <TheBox />
                <TheBox />
                <Box textAlign='center'>
                    <Button component={Link} to={'/Index/Chapter2'} variant="contained" color="errorLight" >Continue to Chapter 2</Button>
                </Box>
                <TheBox />

                <Dialog open={(dialogoAbierto === 1)} onClose={handleDialogClose} maxWidth="md">
                    <Container maxWidth="lg">
                        <TheBox />
                        <IntroActivity1 size={props.size} />
                    </Container>
                    <TheBox />
                </Dialog>

                <Dialog open={(dialogoAbierto === 2)} onClose={handleDialogClose} maxWidth="md">
                    <Container maxWidth="lg">
                        <TheBox />
                        <IntroActivity2 size={props.size} />
                    </Container>
                    <TheBox />
                </Dialog>

            </Container>
        </React.Fragment>
    )
}

function anchoSegunBreakpoint(size){
    let margin = 25

    if (size["bpoint"] === "xl" || size["bpoint"] === "lg") {
        return 1200 - (margin * 2)
    }
    return size["width"] - (margin * 2)
}
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Email from "@mui/icons-material/Email"
import { contexto_analytics } from "..";
import { logEvent } from "firebase/analytics";
import SantiagoFoto from "../resources/santiagoCWeb.jpg"

export default function CardSantiago(props){
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    let altoEsqueleto = altoSkeleton(props.size)
    const analytics = React.useContext(contexto_analytics);

    return(
        <React.Fragment>
            <img src={SantiagoFoto} style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onLoad={() => {setLoading(false)}} />
            <Card style={{flexGrow: 1}} >
            { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={altoEsqueleto} /> 
                    </Container>
                :
                <CardMedia 
                    component="img"
                    image={SantiagoFoto}
                />
            }
                <CardContent>
                    <Stack direction="row" alignItems="center">
                        <Typography variant={"h6"}>SANTIAGO LANGER</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText("santiagolanger@gmail.com"); setOpenSnackbar(true) }}>
                            <Email/>
                        </IconButton>
                    </Stack>
                    <a href="https://www.linkedin.com/in/santiago-langer/">LinkedIn</a> 
                    <Typography>Computer Engineering student at University of Buenos Aires - FIUBA (Argentina). </Typography>
                    <Typography>Back-end Software Developer at Mercado Libre. </Typography>
                    <Typography>Assistant Professor at FIUBA</Typography>
                    <Typography>Former tutor at United Nations Model Course for Colegio Nacional de Buenos Aires</Typography>
                    <Typography>He is also experienced with Front-end development.</Typography> 
                </CardContent>
            </Card>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                /* onClose={setOpenSnackbar(false)} */
                message="Email address copied to clipboard"
            />
        </React.Fragment>
    )
}

function altoSkeleton(size){
    if (size["bpoint"] === "xs" || size["bpoint"] === "sm"){
        return size["width"] 
    }

    if (size["bpoint"] === "xl"){
        return ( 1200 - 50 ) / 3
    }

    return size["width"] / 3 -50
}
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Email from "@mui/icons-material/Email"
import { contexto_analytics } from "..";
import { logEvent } from "firebase/analytics";
import barbaraFoto from "../resources/barbaraCWeb.jpg"

export default function CardBarbara(props){
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const analytics = React.useContext(contexto_analytics);
    let altoEsqueleto = altoSkeleton(props.size)

    return(
        <React.Fragment>
            <img src={barbaraFoto} style={{position:"absolute", opacity:0, maxWidth: 1}} alt="for preloading another one" onLoad={() => {setLoading(false)}} />
            <Card style={{flexGrow: 1}} >
            { loading ? 
                    <Container maxWidth={false}>
                        <Skeleton variant="rectangular" height={altoEsqueleto} width={altoEsqueleto} /> 
                    </Container>
                :
                <CardMedia 
                    component="img"
                    image={barbaraFoto}
                />
            }
                <CardContent>
                    <Stack direction="row" alignItems="center">
                        <Typography variant={"h6"}>BÁRBARA RUBIELL</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText("barubiell@gmail.com"); setOpenSnackbar(true) }}>
                            <Email/>
                        </IconButton>
                    </Stack>
                    <Typography>Dr Barbara Rubiell is a Senior Climate Change Scientist at the Centre for Climate Change and Health at the UK Health Security Agency (UKHSA). </Typography>
                    <Typography> With extensive experience in public health projects, she has contributed to significant initiatives, including her role on the Covid-19 Survey Analysis team at the Office for National Statistics and research work at the Mexican Institute of Public Health.</Typography>
                    <Typography> Dr Rubiell holds a PhD in Human Geography from the University of Edinburgh, where she specialised in the study of environmental determinants of health.</Typography>
                </CardContent>
            </Card>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                /* onClose={setOpenSnackbar(false)} */
                message="Email address copied to clipboard"
            />
        </React.Fragment>
    )
}

function altoSkeleton(size){
    if (size["bpoint"] === "xs" || size["bpoint"] === "sm"){
        return size["width"] 
    }

    if (size["bpoint"] === "xl"){
        return ( 1200 - 50 ) / 3
    }

    return size["width"] / 3 -50
}
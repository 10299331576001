import React from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import CardBarbara from "../components/CardBarbara";
import CardEnrique from "../components/CardEnrique";
import CardSantiago from "../components/CardSantiago";
import TheBox from "../components/TheBox";

export default function PaginaIntegrantes(props){
    /* window.scrollTo(0, 0) */
    
    return (
        <React.Fragment>
            <TheBox />
            <TheBox />
            <Container maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} md={4} style={{display: 'flex', }}>
                    <CardBarbara size={props.size}/>
                </Grid>
                <Grid item xs={12} md={4} style={{display: 'flex', }}>
                    <CardEnrique size={props.size}/>
                </Grid>
                <Grid item xs={12} md={4} style={{display: 'flex', }}>
                    <CardSantiago size={props.size}/>
                </Grid>
            </Grid>
            <TheBox />
            <Typography>v1.1.1</Typography>
            </Container>
            <TheBox />
            <TheBox />
        </React.Fragment>
    )
}
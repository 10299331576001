import React from "react";
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import LinkMUI from '@mui/material/Link';
import TheBox from "./TheBox";

import '../resources/App.css';

export default function PrefacioDePagina(){
    return(
        <React.Fragment>
            <Container maxWidth="lg">
            <Typography variant="h5" style={{marginTop: 10, marginBottom: 10}}>Preface </Typography>
                <Typography align="justify">
                    In this electronic book we share content and resources developed during the last
                    decade of teaching activities. Originally, some of <LinkMUI href="https://publichealthinmegacities.wordpress.com/course-overview" target="_blank" color="error">these modules</LinkMUI> were tested and
                    implemented in active learning experiences in the field,  which received excellent evaluations. The years
                    2020 – 2022, however, imposed painful restrictions to those field-oriented
                    courses; but they also opened the opportunity to teach on-line courses (which
                    also prompted excellent evaluations), and develop this electronic book.
                </Typography>
                <TheBox />

                <Typography align="justify">
                    We have already shared some of these resources with hundreds of undergrad
                    and postgrad students from China, India, Africa, Europe, North America, Latin
                    America, and beyond. Curious readers of this electronic book will observe,
                    however, that this is work in progress, i.e., while we already cover a wide range
                    of topics, emerging public health concerns will be added over time, in response
                    to the dynamic nature of this field. Also, we plan to unfold and analyze in more
                    detail some the issues we included in this first edition, e.g., public health lessons
                    from ancient cities; public health in mega slums; water, sanitation and public
                    health in megacities; the current climate crisis, “natural” disasters and public
                    health challenges in rapidly growing cities; the toxic food environment and the
                    epidemic of obesity in large cities; urban violence, and the opioids health crisis
                    and mental health. We will be working on issues not included in this first edition
                    of this electronic book (e.g., future pandemics; syndemics; homelessness).
                    Surely, new teaching activities will provide the opportunity to work with talented
                    students from a diverse background (e.g., health sciences, urban design, social
                    and political sciences, geographers, engineers, lawyers, advocates).
                </Typography>
                <TheBox />

                <Typography align="justify">
                    We hope that undergraduate and postgraduate students, mentors and
                    instructors, as well as interested members of the public will find the book useful,
                    interesting and relevant, and will consider it a good resource for school’s
                    projects, case-based discussions and dissertations. We also hope this e-book
                    will help them understand how scientific evidence and data may illuminate
                    ethical, political, spatial, public health, environmental, and social justice issues.
                </Typography>
                <TheBox />

                <Typography align="right">Queens, NYC., January 2025.</Typography>
            </Container>
        </React.Fragment>
    )
}